import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ToastrModule } from 'ngx-toastr';
import * as bootstrap from 'bootstrap';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { FooterComponent } from './shared/footer/footer.component';
import { AuthorizationHeaderInterceptor, ErrorInterceptor } from './interceptors';

import { FantasyPipeModule } from './pipes/pipes.module';
import {SmsOnlyAccessRoleReroutingService} from './services/sms-only-access-role-rerouting.service';
import {PartialAccessRoleReroutingService} from './services/partial-access-role-rerouting.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    FooterComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    CommonModule,
    FantasyPipeModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    BrowserAnimationsModule,
    NgbModule,
    ToastrModule.forRoot({
      autoDismiss: true,
      closeButton: true,
      maxOpened: 1,
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthorizationHeaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    SmsOnlyAccessRoleReroutingService,
    PartialAccessRoleReroutingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
