import { NgModule } from '@angular/core';

import { SafePipe } from './safe.pipe';
import { RemoveUnderscorePipe } from './remove-underscore.pipe';
import { RoundOffDecimalPipe } from './round-off-decimal';
import { AddOrdinalPipe } from './add-ordinal.pipe';

@NgModule({
    declarations: [
        SafePipe,
        RemoveUnderscorePipe,
        RoundOffDecimalPipe,
        AddOrdinalPipe
    ],
    imports: [],
    exports: [
        SafePipe,
        RemoveUnderscorePipe,
        RoundOffDecimalPipe,
        AddOrdinalPipe
    ],
    providers: [
        SafePipe,
        RemoveUnderscorePipe,
        RoundOffDecimalPipe,
        AddOrdinalPipe
    ]
})
export class FantasyPipeModule { }
