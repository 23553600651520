<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="card-group">
                <div class="card p-4">
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="card-body">
                            <h1>Login</h1>
                            <p class="text-muted">Sign In to your account</p>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="icon-user"></i>
                                    </span>
                                </div>
                                <input class="form-control" type="text" placeholder="Email" formControlName="email"
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                                    <div *ngIf="f['email'].errors.required">Email is required</div>
                                    <div *ngIf="f['email'].errors.email">Email must be a valid email address</div>
                                </div>
                            </div>
                            <div class="input-group mb-4">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="icon-lock"></i>
                                    </span>
                                </div>
                                <input type="password" placeholder="password" formControlName="password"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                                    <div *ngIf="f['password'].errors.required">Password is required</div>
                                    <div *ngIf="f['password'].errors.minlength">Password must be at least 6 characters
                                    </div>
                                </div>
                            </div>

                            <!-- <ngx-recaptcha2 #captchaElem
                                [siteKey]="siteKey"
                                (reset)="handleReset()"
                                (success)="handleSuccess($event)"
                                [useGlobalDomain]="false"
                                [theme]="'light'"
                                formControlName="recaptcha">
                            </ngx-recaptcha2> -->

                            <div class="row">
                                <div class="col-6">
                                    <input class="btn btn-primary px-4" type="submit" value="Login" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
