import {AuthService} from './auth.service';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {ROLE_PARTIAL_ADMIN, ROLE_SUPER_ADMIN} from '../../constants';

@Injectable()
export class PartialAccessRoleReroutingService  implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const retrieveRoleId = this.authService.retrieveRoleId;
        const roleId = parseInt(retrieveRoleId, 10);

        if (retrieveRoleId !== '' && ((roleId === ROLE_PARTIAL_ADMIN)
            || (roleId === ROLE_SUPER_ADMIN))) {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}
