import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class AuthService {
    private currentUserTokenSubject: BehaviorSubject<string>;
    public currentUserToken: Observable<string>;

    private isAuthenticatedSubject: BehaviorSubject<boolean>;
    public isAuthenticated: Observable<boolean>;

    private currentRoleIdSubject: BehaviorSubject<string>;
    public currentRoleId: Observable<string>;

    constructor(private http: HttpClient, private router: Router) {
        this.currentUserTokenSubject = new BehaviorSubject<string>(this.getToken());
        this.currentUserToken = this.currentUserTokenSubject.asObservable();

        const isLoggedIn = this.getToken().length > 0 ? true : false;
        this.isAuthenticatedSubject = new BehaviorSubject<boolean>(isLoggedIn);
        this.isAuthenticated = this.isAuthenticatedSubject.asObservable();

        this.currentRoleIdSubject = new BehaviorSubject<string>(this.getRoleId());
        this.currentRoleId = this.currentRoleIdSubject.asObservable();
    }

    public login(data: object) {
        return this.http.post(`${environment.API_URL}/login`, data)
            .pipe(map(response => {
                this.setAuthToken(response['Data'].session_key.toString());
                this.setRoleId(response['Data'].role_id.toString());
                this.setTanantName(response['Data'].tenant_name.toString())
                return response;
            }));
    }

    public logout() {
        return this.http.get(`${environment.API_URL}/logout`)
            .pipe(map(response => {
                this.removeAuthToken();
                this.router.navigate(['/login']);
                return response;
            }));
    }

    public get isUserAuthenticated(): boolean {
        return this.isAuthenticatedSubject.value;
    }

    public get authenticatedToken(): string {
        return this.currentUserTokenSubject.value;
    }

    public get retrieveRoleId(): string {
        return this.currentRoleIdSubject.value;
    }

    private setAuthToken(token: string) {
        localStorage.setItem('AuthToken', token);
        this.currentUserTokenSubject.next(token);
        this.isAuthenticatedSubject.next(true);
    }

    private getToken(): string {
        return localStorage.getItem('AuthToken') || '';
    }

    private removeAuthToken() {
        localStorage.clear(); // This clears the value for both AuthToken, Role_Id and tenant_name in localStorage
        this.currentUserTokenSubject.next(null);
        this.isAuthenticatedSubject.next(false);
    }

    private setTanantName(name: string) {
        localStorage.setItem('tenant_name', name);
    }

    private setRoleId(role_id: string) {
        localStorage.setItem('Role_Id', role_id);
        this.currentRoleIdSubject.next(role_id);
    }

    private getRoleId(): string {
        return localStorage.getItem('Role_Id') || '';
    }


}
