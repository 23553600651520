import * as jQuery from 'jquery';

export function encryptPassword(password: string): string {
  return btoa(password);
}

export function range(size: number, startAt = 1) {
  const ans = [];
  for (let i = startAt; i <= size; i++) {
    ans.push(i);
  }
  return ans;
}

export function hideModal(id: string) {
  jQuery(`#${id}`).removeClass('show');
  jQuery('.modal-backdrop').remove();
}

export function formatDate(date: Date | string) {
  const dateToFormat = (typeof (date) === 'string') ? new Date(date) : date;
  return `${dateToFormat.getFullYear()}-${dateToFormat.getMonth() + 1}-${dateToFormat.getDate()}`;
}

export function formatDateTime(date: Date | string) {
  const dateToFormat = (typeof (date) === 'string') ? new Date(date) : date;
  return `${dateToFormat.getFullYear()}-${dateToFormat.getMonth() + 1}-${dateToFormat.getDate()} ${dateToFormat.getHours()}:${dateToFormat.getMinutes()}:${dateToFormat.getSeconds()}`;
}
export function formatDateforPicker(date: Date | string) {
  const dateToFormat = (typeof (date) === 'string') ? new Date(date) : date;

  var day =  dateToFormat.getDate();
  var month =  dateToFormat.getMonth() + 1

  return `${day}-${month}-${dateToFormat.getFullYear()}`;
}

export function formatTime(date: Date | string) {
  const dateToFormat = (typeof (date) === 'string') ? new Date(date) : date;
  return `${dateToFormat.getHours()}:${dateToFormat.getMinutes()}:${dateToFormat.getSeconds()}`;
}